<template>
  <router-view> </router-view>
</template>

<style lang="scss">
// Main Stylesheet
@import "./src/assets/scss/main";

// Bootstrap Vue Stylesheet
@import "~bootstrap-vue/src/index";
</style>

<script>
export default {
  name: "App",

  data: () => ({}),

  mounted() {
    // document.documentElement.style.setProperty("--main-color", "red");
    document.addEventListener("mouseup", event => {
      this.getSetting(true);
      //get sidebar notif
      if (localStorage.getItem("user-info") != null) {
        this.$axios
          .post("/v2/general/notification/sidebar", {}, this.gl_req_header())
          .then(res => {
            if (res.status === 200) {
              this.$store.commit("setSbNotif", res.data.data);
            }
          })
          .catch(e => {
            console.log(e);
          });
      }

      //print dropdown auto dismiss
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    });
    this.getSetting(false);
  },

  created() {
    setInterval(this.checkCamera, 5000);
  },

  methods: {
    //Kamera aktif terus jika saat foto attendance, user navigate ke menu lain
    checkCamera() {
      if (
        this.$router.currentRoute.name != "AttendancePicture" &&
        // this.$router.currentRoute.name != "AttendanceFacePicture" &&
        this.$router.currentRoute.name != "VisitationPicture" &&
        this.$router.currentRoute.name != "BreakPicture" &&
        this.$router.currentRoute.name != "CekDevices" &&
        this.$router.currentRoute.name != "AbsenQRRead" &&
        this.$router.currentRoute.name != "AbsenPicture" &&
        this.$router.currentRoute.name != "UserEventCI" &&
        this.$router.currentRoute.name != "AttendanceModPicture" &&
        window.localStream != undefined
      ) {
        window.localStream.getTracks().forEach(function(track) {
          track.stop();
        });
        window.localStream = null;
      }
    },

    setFocusBtnScan() {
      // set btnscan focus
      if (document.getElementById("btnscan")) {
        document.getElementById("btnscan").focus();
        // this.$refs.btnscan.focus();
      }
    },

    getSetting(s = false) {
      let env = process.env.VUE_APP_ENV;
      if (env != "productionn") {
        return;
      }
      const x = JSON.parse(localStorage.getItem("setting-apps"));
      if (x) {
        if (s) return;

        if (
          !x.background_path ||
          !x.logo_path_1 ||
          !x.logo_path_2 ||
          !x.color_1 ||
          !x.color_2 ||
          !x.color_3
        ) {
          this.$router.push({ name: "Login" });
        }

        if (x.background_path) {
          const bg_image = "url(" + x.background_path.file_url + ")";
          document.documentElement.style.setProperty("--bg-image", bg_image);
        }

        if (x.logo_path_1) {
          const sd_image = "url(" + x.logo_path_1.file_url + ")";
          document.documentElement.style.setProperty("--side-image", sd_image);
        }

        if (x.logo_path_2) {
          const lg_image = "url(" + x.logo_path_2.file_url + ")";
          document.documentElement.style.setProperty("--login-image", lg_image);
        }

        if (x.color_1) {
          document.documentElement.style.setProperty("--main-color", x.color_1);
        }

        if (x.color_2) {
          document.documentElement.style.setProperty(
            "--hover-color",
            x.color_2
          );
        }

        if (x.color_3) {
          document.documentElement.style.setProperty(
            "--second-color",
            x.color_3
          );
        }
      } else {
        this.$router.push({ name: "Login" });
      }
    }
  }
};
</script>
