var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classContainer, attrs: { id: "page-container" } },
    [
      _vm.$store.state.layout.sidebar
        ? _c(
            "base-sidebar",
            { class: _vm.layoutClasses.sidebar },
            [_vm._t("sidebar")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.settings.sidebarVisibleMobile
        ? _c("div", {
            staticStyle: {
              "z-index": "1031",
              width: "100vw",
              height: "100vh",
              "background-color": "rgba(0, 0, 0, 0.25)",
              position: "fixed"
            },
            on: {
              click: function($event) {
                _vm.$store.state.settings.sidebarVisibleMobile = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.layout.header
        ? _c(
            "base-header",
            { class: _vm.layoutClasses.header },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "main-container" } },
        [
          _c("vue-confirm-dialog", {
            staticClass: "custom-vue-confirm-dialog"
          }),
          _vm._v(" "),
          _vm._t("content"),
          _vm._v(" "),
          _c("router-view")
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            "hide-footer": "",
            size: "lg",
            "hide-header": "",
            "no-close-on-backdrop": ""
          },
          model: {
            value: _vm.need_update,
            callback: function($$v) {
              _vm.need_update = $$v
            },
            expression: "need_update"
          }
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "tnt-main-color text-center",
                    staticStyle: { "font-size": "6rem" }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-sync-alt",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("b-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "font-weight-bold tnt-gray-color text-center mb-3 h5"
                  },
                  [
                    _vm._v(
                      "\n          Anda diwajibkan untuk memperbarui Aplikasi Snapp\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("b-col", { staticClass: "mb-2", attrs: { cols: "12" } }, [
                _c(
                  "button",
                  {
                    staticClass: "tnt-btn tnt-btn-prime btn-lg w-100 py-2",
                    staticStyle: { "font-size": "1.3rem" },
                    on: {
                      click: function($event) {
                        return _vm.submitUpdate()
                      }
                    }
                  },
                  [_vm._v("\n          Perbarui Aplikasi\n        ")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }