var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-3" },
    _vm._l(_vm.arrDat, function(v, i) {
      return _c(
        "span",
        { key: "humzzz-" + i, staticClass: "mr-1" },
        [
          i + 1 != _vm.arrDat.length
            ? [
                _c(
                  "router-link",
                  {
                    staticClass: "tnt-main-color this-hover",
                    staticStyle: { "font-weight": "600" },
                    attrs: { to: { name: v.route } }
                  },
                  [_vm._v(_vm._s(v.text))]
                ),
                _vm._v(" "),
                _c("b-icon-chevron-right", {
                  staticClass: "active-route",
                  staticStyle: { height: "12px", "margin-bottom": "1px" }
                })
              ]
            : [
                _c("span", { staticClass: "active-route" }, [
                  _vm._v(_vm._s(v.text))
                ])
              ]
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }