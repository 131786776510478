<template>
  <div class="mb-3">
    <span v-for="(v, i) in arrDat" :key="'humzzz-' + i" class="mr-1">
      <template v-if="i + 1 != arrDat.length">
        <router-link
          :to="{ name: v.route }"
          class="tnt-main-color this-hover"
          style="font-weight: 600"
          >{{ v.text }}</router-link
        >
        <b-icon-chevron-right
          class="active-route"
          style="height: 12px; margin-bottom: 1px"
        />
      </template>
      <template v-else>
        <span class="active-route">{{ v.text }}</span>
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",

  props: {
    Dashboard: Boolean,
    Attendance: Boolean,
    AttendanceQR: Boolean,
    AttendanceQRPicture: Boolean,
    AttendanceHistory: Boolean,
    AttendancePicture: Boolean,
    AttendanceRequestForm: Boolean,
    AttendanceRequestHistory: Boolean,
    AttendanceOverTimeForm: Boolean,
    AttendanceOverTimeHistory: Boolean,
    OverTimeReport: Boolean,
    // break
    Break: Boolean,
    BreakHistory: Boolean,
    BreakPicture: Boolean,
    BreakRequestForm: Boolean,
    BreakReport: Boolean,
    // visitation
    Visitation: Boolean,
    VisitationHistory: Boolean,
    VisitationPicture: Boolean,
    VisitationRequestForm: Boolean,
    VisitationReport: Boolean,

    Timeoff: Boolean,
    TimeoffForm: Boolean,
    TimeoffDetail: Boolean,

    Reimburse: Boolean,
    ReimburseCreate: Boolean,
    ReimburseEdit: Boolean,
    ReimburseDetail: Boolean,

    CashAdvance: Boolean,
    CashAdvanceDetail: Boolean,
    CashAdvanceAdd: Boolean,

    Approval: Boolean,
    NewApproval: Boolean,
    ApprovalHistory: Boolean,
    ApprovalDetail: Boolean,

    SuratTugas: Boolean,
    SuratTugasCreate: Boolean,
    SuratTugasDetail: Boolean,
    SuratTugasHistory: Boolean,

    Kasir: Boolean,
    KasirDetail: Boolean,

    FinanceCashAdvance: Boolean,

    FinanceReimburse: Boolean,
    FinanceApprovalReimburse: Boolean,

    PaymentVoucher: Boolean,
    PaymentVoucherDetail: Boolean,

    PettyCash: Boolean,
    PettyCashLedger: Boolean,
    PettyCashTopup: Boolean,
    SuratTugasManage: Boolean,

    AttendanceReport: Boolean,
    AttendanceRequestReport: Boolean,
    DailyAttendanceReport: Boolean,
    DailyAttendanceReport2: Boolean,
    TimeOffReport: Boolean,
    AttendanceShift: Boolean,
    AttendanceCuti: Boolean,
    AttendanceReport2: Boolean,
    AttendanceSetting: Boolean,
    ManagerOnDuty: Boolean,
    AttendanceModPicture: Boolean,

    Announcement: Boolean,
    AnnouncementCreate: Boolean,
    AnnouncementEdit: Boolean,
    AnnouncementDetail: Boolean,

    EmployeeMutasi: Boolean,
    EmployeeMutasiCreate: Boolean,
    EmployeeMutasiView: Boolean,
    // AnnouncementEdit: Boolean,
    // AnnouncementDetail: Boolean,
    Event: Boolean,
    EventUser: Boolean,
    EventDetail: Boolean,
    EventForm: Boolean,

    OffMasal: Boolean,

    Maintenance: Boolean,
    Handover: Boolean,

    Reprimand: Boolean,
    ReprimandDetail: Boolean,

    Category: Boolean,
    Company: Boolean,
    Vendor: Boolean,
    Division: Boolean,
    PayrollAccess: Boolean,
    Divisi: Boolean,
    ApprovalSetting: Boolean,
    CompanyPettyCash: Boolean,
    Employee: Boolean,
    EmpTitle: Boolean,
    EmployeeRegister: Boolean,
    EmployeeContract: Boolean,
    EmployeeAdd: Boolean,
    EmployeeEdit: Boolean,
    EmployeeResign: Boolean,
    EmployeeContact: Boolean,
    EmployeeChart: Boolean,

    CompanyBank: Boolean,

    Calendar: Boolean,

    Settings: Boolean,
    SettingProfile: Boolean,
    SettingJobs: Boolean,
    AssetOnHand: Boolean,
    SettingCompany: Boolean,
    SettingBank: Boolean,
    SettingPassword: Boolean,
    SettingBank3: Boolean,
    SettingLocation: Boolean,
    SettingTimeoffType: Boolean,
    LoginDevices: Boolean,
    UserBank: Boolean,
    SettingReprimand: Boolean,
    SettingReprimandDetail: Boolean,
    SettingLoan: Boolean,
    SettingGaji: Boolean,
    CekDevices: Boolean,
    SettingOther: Boolean,

    ChangeLog: Boolean,

    Notification: Boolean,

    Asset: Boolean,
    KodeShift: Boolean,

    MessageHistory: Boolean,
    EmployeeActivity: Boolean,
    ActionLog: Boolean,
    QueryLog: Boolean,
    EmployeeImport: Boolean,
    CompanyAccess: Boolean,
    SetParam: Boolean,
    Brand: Boolean,
    AttendanceExchangeShiftForm: Boolean,
    AttendanceExchangeShiftHistory: Boolean,
    AttendanceExchangeShiftReport: Boolean,

    // payroll
    PayrollConfig: Boolean,
    PayrollEmployee: Boolean,
    PayrollList: Boolean,
    PayrollListDetail: Boolean,

    // loadn
    Loan: Boolean,
    LoanDetail: Boolean,
    LoanDetailD: Boolean,
    LoanCategory: Boolean,
    SettingLoanHistory: Boolean,
    SettingLoanPayHistory: Boolean,

    PengajuanReport: Boolean,

    Complain: Boolean,
    ComplainForm: Boolean,
    ComplainDetail: Boolean,

    Billing: Boolean,
    MenuConfig: Boolean,
    GroupMenu: Boolean,

    CompanyRules: Boolean,
    CompanyRulesForm: Boolean,
    CompanyRulesFormAdd: Boolean,
    CompanyRulesUser: Boolean,
    CompanyRulesUserDetail: Boolean,

    SetCheckList: Boolean,
    SetCheckListCreate: Boolean,
    SetCheckListEdit: Boolean,

    TaskCheckList: Boolean,
    TaskCheckListDetail: Boolean,

    SettingChecklist: Boolean,
    ChecklistForm: Boolean,
    ChecklistDetail: Boolean
  },

  components: {},

  data: () => ({
    arrDat: []
  }),

  computed: {},

  mounted() {},

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (this.Dashboard)
        this.arrDat.push({
          route: "Dashboard",
          text: "Dashboard"
        });

      if (this.Attendance)
        this.arrDat.push({
          route: "Attendance",
          text: "Kehadiran"
        });

      if (this.AttendanceQR)
        this.arrDat.push({
          route: "AttendanceAdmin",
          text: "Scan QR"
        });
      if (this.AttendanceQRPicture)
        this.arrDat.push({
          route: "AbsenPicture",
          text: "Form"
        });
      if (this.AttendanceHistory)
        this.arrDat.push({
          route: "AttendanceHistory",
          text: "Riwayat"
        });
      if (this.AttendancePicture)
        this.arrDat.push({
          route: "AttendancePicture",
          text: "Form"
        });
      if (this.AttendanceRequestForm)
        this.arrDat.push({
          route: "AttendanceRequestForm",
          text: "Buat Pengajuan Kehadiran"
        });

      if (this.AttendanceRequestHistory)
        this.arrDat.push({
          route: "AttendanceRequestHistory",
          text: "Riwayat Pengajuan Kehadiran"
        });

      if (this.AttendanceOverTimeForm)
        this.arrDat.push({
          route: "AttendanceOverTimeForm",
          text: "Buat Pengajuan Lembur"
        });

      if (this.AttendanceOverTimeHistory)
        this.arrDat.push({
          route: "AttendanceOverTimeHistory",
          text: "Riwayat Pengajuan Lembur"
        });

      if (this.OverTimeReport)
        this.arrDat.push({
          route: "OverTimeReport",
          text: "Laporan Lembur"
        });

      // tukar shift
      if (this.AttendanceExchangeShiftForm)
        this.arrDat.push({
          route: "AttendanceExchangeShiftForm",
          text: "Buat Pengajuan Ganti Shift"
        });

      if (this.AttendanceExchangeShiftHistory)
        this.arrDat.push({
          route: "AttendanceExchangeShiftHistory",
          text: "Riwayat Pengajuan Ganti Shift"
        });
      if (this.AttendanceExchangeShiftReport)
        this.arrDat.push({
          route: "AttendanceExchangeShiftReport",
          text: "Laporan Ganti Shift"
        });

      // break
      if (this.Break)
        this.arrDat.push({
          route: "Break",
          text: "Break"
        });
      if (this.BreakHistory)
        this.arrDat.push({
          route: "BreakHistory",
          text: "Riwayat"
        });
      if (this.BreakPicture)
        this.arrDat.push({
          route: "BreakPicture",
          text: "Form"
        });
      if (this.BreakRequestForm)
        this.arrDat.push({
          route: "BreakRequestForm",
          text: "Pengajuan"
        });
      if (this.BreakReport)
        this.arrDat.push({
          route: "BreakReport",
          text: "Laporan Break"
        });

      // visitation
      if (this.Visitation)
        this.arrDat.push({
          route: "Visitation",
          text: "Kunjungan"
        });
      if (this.VisitationHistory)
        this.arrDat.push({
          route: "VisitationHistory",
          text: "Riwayat"
        });
      if (this.VisitationPicture)
        this.arrDat.push({
          route: "VisitationPicture",
          text: "Form"
        });
      if (this.VisitationRequestForm)
        this.arrDat.push({
          route: "VisitationRequestForm",
          text: "Pengajuan"
        });

      if (this.VisitationReport)
        this.arrDat.push({
          route: "VisitationReport",
          text: "Laporan Kunjungan"
        });

      if (this.Timeoff)
        this.arrDat.push({
          route: "Timeoff",
          text: "Cuti"
        });

      if (this.TimeoffForm)
        this.arrDat.push({
          route: "TimeoffForm",
          text: "Buat Pengajuan Cuti"
        });

      if (this.TimeoffDetail)
        this.arrDat.push({
          route: "TimeoffDetail",
          text: "Detail Pengajuan Cuti"
        });

      if (this.Complain)
        this.arrDat.push({
          route: "Complain",
          text: "Pengaduan"
        });

      if (this.ComplainForm)
        this.arrDat.push({
          route: "TimeoffForm",
          text: "Buat Pengaduan"
        });

      if (this.ComplainDetail)
        this.arrDat.push({
          route: "TimeoffDetail",
          text: "Detail Pengaduan"
        });

      if (this.Reimburse)
        this.arrDat.push({
          route: "Reimburse",
          text: "Reimburse"
        });
      if (this.ReimburseCreate)
        this.arrDat.push({
          route: "Reimburse",
          text: "Tambah Reimburse"
        });
      if (this.ReimburseEdit)
        this.arrDat.push({
          route: "Reimburse",
          text: "Edit Reimburse"
        });
      if (this.ReimburseDetail)
        this.arrDat.push({
          route: "ReimburseDetail",
          text: "Detail"
        });

      if (this.CashAdvance)
        this.arrDat.push({
          route: "CashAdvance",
          text: "Cash Advance"
        });
      if (this.CashAdvanceDetail)
        this.arrDat.push({
          route: "CashAdvanceDetail",
          text: "Detail Cash Advance"
        });
      if (this.CashAdvanceAdd)
        this.arrDat.push({
          route: "CashAdvanceAdd",
          text: "Buat Cash Advance"
        });

      if (this.Approval)
        this.arrDat.push({
          route: "Approval",
          text: "Approval"
        });

      if (this.NewApproval)
        this.arrDat.push({
          route: "NewApproval",
          text: "Approval"
        });

      if (this.ApprovalHistory)
        this.arrDat.push({
          route: "ApprovalHistory",
          text: "Riwayat"
        });

      if (this.ApprovalDetail)
        this.arrDat.push({
          route: "ApprovalDetail",
          text: "Detail"
        });

      if (this.SuratTugas)
        this.arrDat.push({
          route: "SuratTugas",
          text: "Surat Tugas"
        });
      if (this.SuratTugasCreate)
        this.arrDat.push({
          route: "SuratTugasCreate",
          text: "Buat Surat Dinas Luar Kota"
        });
      if (this.SuratTugasDetail)
        this.arrDat.push({
          route: "SuratTugasDetail",
          text: "Surat Tugas Detail"
        });
      if (this.SuratTugasHistory)
        this.arrDat.push({
          route: "SuratTugasHistory",
          text: "Riwayat"
        });

      if (this.Kasir)
        this.arrDat.push({
          route: "Kasir",
          text: "Kasir Petty Cash"
        });

      if (this.KasirDetail)
        this.arrDat.push({
          route: "KasirApproval",
          text: "Detail"
        });

      if (this.FinanceCashAdvance)
        this.arrDat.push({
          route: "FinanceCashAdvance",
          text: "Finance Cash Advance"
        });

      if (this.FinanceReimburse)
        this.arrDat.push({
          route: "FinanceReimburse",
          text: "Finance Reimburse"
        });

      if (this.FinanceApprovalReimburse)
        this.arrDat.push({
          route: "FinanceApprovalReimburse",
          text: "Detail"
        });

      if (this.PaymentVoucher)
        this.arrDat.push({
          route: "PaymentVoucher",
          text: "Payment Voucher"
        });
      if (this.PaymentVoucherDetail)
        this.arrDat.push({
          route: "PaymentVoucherDetail",
          text: "Detail"
        });

      if (this.PettyCash)
        this.arrDat.push({
          route: "PettyCash",
          text: "Petty Cash"
        });

      if (this.PettyCashLedger)
        this.arrDat.push({
          route: "PettyCash",
          text: "Petty Cash Detail"
        });

      if (this.PettyCashTopup)
        this.arrDat.push({
          route: "PettyCashTopup",
          text: "Pengajuan"
        });

      if (this.SuratTugasManage)
        this.arrDat.push({
          route: "SuratTugasManage",
          text: "Laporan Surat Tugas"
        });

      if (this.AttendanceReport)
        this.arrDat.push({
          route: "AttendanceReport",
          text: "Rekap Kehadiran"
        });
      if (this.DailyAttendanceReport)
        this.arrDat.push({
          route: "AttendanceReport",
          text: "Laporan Kehadiran"
        });
      if (this.DailyAttendanceReport2)
        this.arrDat.push({
          route: "AttendanceReport",
          text: "Kehadiran Harian"
        });
      if (this.TimeOffReport)
        this.arrDat.push({
          route: "TimeOffReport",
          text: "Laporan Cuti"
        });

      if (this.AttendanceShift)
        this.arrDat.push({
          route: "AttendanceShift",
          text: "Setting Shift"
        });

      if (this.AttendanceCuti)
        this.arrDat.push({
          route: "cutiReport",
          text: "Cuti"
        });

      if (this.AttendanceReport2)
        this.arrDat.push({
          route: "AttendanceReport",
          text: "Setting Shift"
        });

      if (this.ManagerOnDuty)
        this.arrDat.push({
          route: "ManagerOnDuty",
          text: "Manager On Duty"
        });

      if (this.AttendanceModPicture)
        this.arrDat.push({
          route: "AttendanceModPicture",
          text: "Form"
        });

      if (this.AttendanceSetting)
        this.arrDat.push({
          route: "AttendanceSetting",
          text: "Setting Jadwal Kehadiran"
        });

      if (this.Announcement)
        this.arrDat.push({
          route: "Announcement",
          text: "Pengumuman"
        });
      if (this.AnnouncementCreate)
        this.arrDat.push({
          route: "AnnouncementCreate",
          text: "Buat Pengumuman"
        });
      if (this.AnnouncementEdit)
        this.arrDat.push({
          route: "AnnouncementEdit",
          text: "Edit Pengumuman"
        });

      if (this.AnnouncementDetail)
        this.arrDat.push({
          route: "AnnouncementDetail",
          text: "Detail Pengumuman"
        });

      if (this.EmployeeMutasi)
        this.arrDat.push({
          route: "EmployeMutation",
          text: "Mutasi Karyawan"
        });

      if (this.EmployeeMutasiCreate)
        this.arrDat.push({
          route: "EmployeMutationCreate",
          text: "Buat Mutasi Karyawan"
        });
      if (this.EmployeeMutasiView)
        this.arrDat.push({
          route: "EmployeMutationCreate",
          text: "Detail Mutasi Karyawan"
        });

      if (this.Event)
        this.arrDat.push({
          route: "Event",
          text: "Set. Kegiatan"
        });

      if (this.EventForm)
        this.arrDat.push({
          route: "EventForm",
          text: "Buat Kegiatan"
        });

      if (this.EventUser)
        this.arrDat.push({
          route: "EventUser",
          text: "Kegiatan"
        });

      if (this.EventDetail)
        this.arrDat.push({
          route: "Event",
          text: "Detail Kegiatan"
        });

      if (this.SetCheckList)
        this.arrDat.push({
          route: "ChecklistTemplate",
          text: "Set. Checklist"
        });

      if (this.SetCheckListCreate)
        this.arrDat.push({
          route: "ChecklistTemplateAdmin",
          text: "Buat Checklist"
        });

      if (this.SetCheckListEdit)
        this.arrDat.push({
          route: "ChecklistTemplateAdmin",
          text: "Edit Checklist"
        });

      if (this.TaskCheckList)
        this.arrDat.push({
          route: "ChecklistTask",
          text: "Task Checklist"
        });

      if (this.TaskCheckListDetail)
        this.arrDat.push({
          route: "ChecklistTaskDetail",
          text: "Detail Checklist"
        });

      if (this.OffMasal)
        this.arrDat.push({
          route: "TimeOffMasal",
          text: "Set. Cuti Massal"
        });

      if (this.Maintenance)
        this.arrDat.push({
          route: "Maintenance",
          text: "Maintenance"
        });
      if (this.Handover)
        this.arrDat.push({
          route: "Handover",
          text: "Serah Terima Asset"
        });
      if (this.Reprimand)
        this.arrDat.push({
          route: "Reprimand",
          text: "Surat Peringatan"
        });

      if (this.ReprimandDetail)
        this.arrDat.push({
          route: "Reprimand",
          text: "Detail"
        });

      if (this.Category)
        this.arrDat.push({
          route: "InformationCategory",
          text: "Kategori"
        });
      if (this.Company)
        this.arrDat.push({
          route: "InformationCompany",
          text: "Perusahaan"
        });
      if (this.Vendor)
        this.arrDat.push({
          route: "vendor",
          text: "Vendor"
        });
      if (this.Division)
        this.arrDat.push({
          route: "InformationDivision",
          text: "Divisi"
        });
      if (this.Divisi)
        this.arrDat.push({
          route: "InformationDivision",
          text: "Divisi"
        });
      if (this.PayrollAccess)
        this.arrDat.push({
          route: "InformationPayrollAccess",
          text: "Akses Payroll"
        });

      if (this.ApprovalSetting)
        this.arrDat.push({
          route: "InformationApproval",
          text: "Setting Approval"
        });
      if (this.EmployeeContact)
        this.arrDat.push({
          route: "ContactNumber",
          text: "Kontak Karyawan"
        });
      if (this.EmployeeChart)
        this.arrDat.push({
          route: "InformationEmployeeChart",
          text: "Chart Karyawan"
        });
      if (this.Calendar)
        this.arrDat.push({
          route: "Calendar",
          text: "Kalender"
        });

      if (this.CompanyPettyCash)
        this.arrDat.push({
          route: "CompanyPettyCash",
          text: "Pengaturan Petty Cash"
        });
      if (this.Employee)
        this.arrDat.push({
          route: "Employee",
          text: "Karyawan"
        });

      if (this.EmpTitle)
        this.arrDat.push({
          route: "InformationEmpTitle",
          text: "Titel Karyawan"
        });

      if (this.EmployeeRegister)
        this.arrDat.push({
          route: "EmployeeRegister",
          text: "Tambah Karyawan"
        });
      if (this.EmployeeContract)
        this.arrDat.push({
          route: "EmployeeContract",
          text: "Kontrak Karyawan"
        });

      if (this.EmployeeAdd)
        this.arrDat.push({
          route: "EmployeeAdd",
          text: "Registrasi Karyawan"
        });
      if (this.EmployeeEdit)
        this.arrDat.push({
          route: "EmployeeEdit",
          text: "Ubah Karyawan"
        });

      if (this.EmployeeResign)
        this.arrDat.push({
          route: "EmployeeResign",
          text: "Karyawan Resign"
        });

      if (this.CompanyBank)
        this.arrDat.push({
          route: "CompanyBank",
          text: "Bank Perusahaan"
        });

      if (this.Settings)
        this.arrDat.push({
          route: "Settings",
          text: "Akun"
        });

      if (this.SettingProfile)
        this.arrDat.push({
          route: "SettingProfile",
          text: "Data Pribadi"
        });
      if (this.SettingJobs)
        this.arrDat.push({
          route: "SettingJobs",
          text: "Data Pekerjaan"
        });
      if (this.AssetOnHand)
        this.arrDat.push({
          route: "SettingAssetOnHand",
          text: "Asset Dimiliki"
        });
      if (this.SettingCompany)
        this.arrDat.push({
          route: "SettingCompany",
          text: "Profil Perusahaan"
        });
      if (this.SettingBank)
        this.arrDat.push({
          route: "SettingBank",
          text: "Rekening Bank Saya"
        });
      if (this.SettingPassword)
        this.arrDat.push({
          route: "SettingPassword",
          text: "Ganti Password"
        });
      if (this.SettingBank3)
        this.arrDat.push({
          route: "SettingBank3",
          text: "Rekening Bank Pihak Ke-3"
        });
      if (this.SettingLocation)
        this.arrDat.push({
          route: "CompanyLocation",
          text: "Setting Lokasi"
        });
      if (this.SettingTimeoffType)
        this.arrDat.push({
          route: "CompanyTimeoffType",
          text: "Setting Tipe Cuti"
        });

      if (this.CompanyRules)
        this.arrDat.push({
          route: "InformationCompanyRules",
          text: "Peraturan Perusahaan"
        });
      if (this.CompanyRulesForm)
        this.arrDat.push({
          route: "InformationCompanyRulesForm",
          text: "Edit Peraturan Perusahaan"
        });
      if (this.CompanyRulesFormAdd)
        this.arrDat.push({
          route: "InformationCompanyRulesForm",
          text: "Buat Peraturan Perusahaan"
        });
      if (this.CompanyRulesUser)
        this.arrDat.push({
          route: "SettingCompanyRules",
          text: "Peraturan Perusahaan"
        });

      if (this.CompanyRulesUserDetail)
        this.arrDat.push({
          route: "PengaturanCompanyRulesDetail",
          text: "Peraturan Perusahaan"
        });
      if (this.LoginDevices)
        this.arrDat.push({
          route: "LoginDevices",
          text: "Perangkat Aktif"
        });
      if (this.UserBank)
        this.arrDat.push({
          route: "UserBankInfo",
          text: "Daftar Akun Bank"
        });

      if (this.SettingReprimand)
        this.arrDat.push({
          route: "SettingReprimand",
          text: "Surat Peringatan"
        });

      if (this.SettingReprimandDetail)
        this.arrDat.push({
          route: "SettingReprimandDetail",
          text: "Detail"
        });
      if (this.SettingLoan)
        this.arrDat.push({
          route: "SettingLoan",
          text: "Pinjaman"
        });

      if (this.SettingLoanHistory)
        this.arrDat.push({
          route: "SettingLoanHistory",
          text: "Riwayat Pinjaman"
        });

      if (this.SettingLoanPayHistory)
        this.arrDat.push({
          route: "SettingLoanPayHistory",
          text: "Riwayat Pembayaran"
        });

      if (this.SettingGaji)
        this.arrDat.push({
          route: "SettingGaji",
          text: "Pengupahan"
        });

      if (this.SettingChecklist)
        this.arrDat.push({
          route: "UserChecklist",
          text: "Checklist"
        });
      if (this.ChecklistForm)
        this.arrDat.push({
          route: "UserChecklistForm",
          text: "Buat Checklist"
        });
      if (this.ChecklistDetail)
        this.arrDat.push({
          route: "UserChecklistDetail",
          text: "Detail Checklist"
        });

      if (this.CekDevices)
        this.arrDat.push({
          route: "CekDevices",
          text: "Cek Perangkat"
        });

      if (this.SettingOther)
        this.arrDat.push({
          route: "SettingOther",
          text: "Lainnya"
        });

      if (this.ChangeLog)
        this.arrDat.push({
          route: "ChangeLog",
          text: "Change Log"
        });
      if (this.Notification)
        this.arrDat.push({
          route: "notifikasi",
          text: "Notifikasi"
        });

      if (this.Asset)
        this.arrDat.push({
          route: "Asset",
          text: "Asset"
        });
      if (this.KodeShift)
        this.arrDat.push({
          route: "InformationShift",
          text: "Kode Shift"
        });

      if (this.MessageHistory)
        this.arrDat.push({
          route: "MessageHistory",
          text: "Riwayat Pesan"
        });
      if (this.EmployeeActivity)
        this.arrDat.push({
          route: "EmployeeActivity",
          text: "Employee Activity"
        });
      if (this.ActionLog)
        this.arrDat.push({
          route: "ActionLog",
          text: "Action Log"
        });
      if (this.QueryLog)
        this.arrDat.push({
          route: "QueryLog",
          text: "Error Query Log"
        });
      if (this.EmployeeImport)
        this.arrDat.push({
          route: "EmployeeImport",
          text: "Import Karyawan"
        });
      if (this.CompanyAccess)
        this.arrDat.push({
          route: "InformationCompanyAccess",
          text: "Set. Akses Perusahaan"
        });

      if (this.Brand)
        this.arrDat.push({
          route: "brand",
          text: "Brand"
        });

      if (this.SetParam)
        this.arrDat.push({
          route: "SettingParam",
          text: "Setting Param"
        });

      if (this.MenuConfig)
        this.arrDat.push({
          route: "MenuConfig",
          text: "Set. Feature Status"
        });
      if (this.GroupMenu)
        this.arrDat.push({
          route: "GroupMenu",
          text: "Set. Group Feature"
        });

      if (this.Billing)
        this.arrDat.push({
          route: "Billing",
          text: "Billing"
        });

      // payroll
      if (this.PayrollConfig)
        this.arrDat.push({
          route: "PayrollSetting",
          text: "Set. Upah"
        });
      if (this.PayrollEmployee)
        this.arrDat.push({
          route: "PayrollEmployee",
          text: "Daftar Karyawan"
        });

      if (this.PayrollList)
        this.arrDat.push({
          route: "PayrollList",
          text: "Daftar Upah"
        });
      if (this.PayrollListDetail)
        this.arrDat.push({
          route: "PayrollListDetail",
          text: "Detail"
        });

      if (this.Loan)
        this.arrDat.push({
          route: "LoanView",
          text: "Pinjaman"
        });
      if (this.LoanDetail)
        this.arrDat.push({
          route: "LoanDetail",
          text: "Detail"
        });
      if (this.LoanDetailD)
        this.arrDat.push({
          route: "LoanDetail",
          text: "Detail Per Pinjaman"
        });

      if (this.LoanCategory)
        this.arrDat.push({
          route: "InformationKategoriPinjaman",
          text: "Kategori Pinjaman"
        });

      if (this.AttendanceRequestReport)
        this.arrDat.push({
          route: "AttendanceRequestReport",
          text: "Laporan Pengajuan Kehadiran"
        });

      // laporan pengajuan
      if (this.PengajuanReport)
        this.arrDat.push({
          route: "PengajuanReport",
          text: "Laporan"
        });
    }
  }
};
</script>

<style scoped>
.active-route {
  color: #808080;
  font-weight: 600;
}

.this-hover:hover {
  text-decoration: none;
  color: #f4ae51;
}
</style>
