var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-2" }, [
    _c(
      "span",
      { staticClass: "back-btn tnt-main-color", on: { click: _vm.nav } },
      [_c("b-icon-chevron-left"), _vm._v("\n    Kembali\n  ")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }