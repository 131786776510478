var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-body-light" }, [
    _c(
      "div",
      { staticClass: "content content-full" },
      [
        _vm._t("default", [
          _vm.title || _vm.$slots.extra
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
                },
                [
                  _vm.title
                    ? _c("h1", { staticClass: "flex-sm-fill h3 my-2" }, [
                        _vm._v("\n          " + _vm._s(_vm.title) + " "),
                        _vm.subtitle
                          ? _c(
                              "small",
                              {
                                staticClass:
                                  "d-block d-sm-inline-block mt-2 mt-sm-0 font-size-base font-w400 text-muted"
                              },
                              [_vm._v(_vm._s(_vm.subtitle))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$slots.extra
                    ? _c(
                        "div",
                        { staticClass: "flex-sm-00-auto ml-sm-3" },
                        [_vm._t("extra")],
                        2
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }