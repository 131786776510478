<template>
  <div id="page-container" :class="classContainer">
    <!-- Page Loader -->
    <!-- <div id="page-loader" :class="{'show': $store.state.settings.pageLoader}"></div> -->
    <!-- END Page Loader -->

    <!-- Page Overlay -->
    <!-- <div id="page-overlay" v-if="$store.state.layout.sideOverlay && $store.state.settings.pageOverlay" @click="() => $store.commit('sideOverlay', { mode: 'close' })"></div> -->
    <!-- END Page Overlay -->

    <!-- Side Overlay -->
    <!-- <base-side-overlay v-if="$store.state.layout.sideOverlay" :class="layoutClasses.sideOverlay"> -->
    <!-- <slot name="side-overlay"></slot> -->
    <!-- </base-side-overlay> -->
    <!-- END Side Overlay -->

    <!-- Sidebar -->
    <base-sidebar
      v-if="$store.state.layout.sidebar"
      :class="layoutClasses.sidebar"
    >
      <slot name="sidebar"></slot>
    </base-sidebar>
    <!-- END Sidebar -->

    <div
      v-if="$store.state.settings.sidebarVisibleMobile"
      style="
      z-index: 1031;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.25);
      position: fixed;
    "
      @click="$store.state.settings.sidebarVisibleMobile = false"
    ></div>

    <!-- <b-modal
      v-model="$store.state.app.needUpdate"
      centered
      title="Daftar Jadwal"
      size="md"
      hide-footer
    >
      <b-row class="mb-2">
        <b-col>
          aa
        </b-col>
      </b-row>
    </b-modal> -->

    <!-- <div
    v-if="store.settings.sidebarVisibleMobile"
    style="
      z-index: 1030;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.25);
      position: fixed;
    "
    @click="store.sidebar({ mode: 'close' })"
  ></div> -->

    <!-- Header -->
    <base-header
      v-if="$store.state.layout.header"
      :class="layoutClasses.header"
    >
      <slot name="header"></slot>
    </base-header>
    <!-- END Header -->

    <!-- Main Container -->
    <div id="main-container">
      <vue-confirm-dialog
        class="custom-vue-confirm-dialog"
      ></vue-confirm-dialog>
      <slot name="content"></slot>
      <!-- <transition name="fade" mode="out-in"> -->
      <router-view></router-view>
      <!-- </transition> -->
    </div>
    <!-- END Main Container -->

    <!-- Footer -->
    <!-- <base-footer v-if="$store.state.layout.footer" :class="layoutClasses.footer || 'bg-body-light'">
      <slot name="footer"></slot>
    </base-footer> -->
    <!-- END Footer -->

    <b-modal
      v-model="need_update"
      centered
      hide-footer
      size="lg"
      hide-header
      no-close-on-backdrop
    >
      <b-row>
        <b-col cols="12" class="mb-2">
          <div class="tnt-main-color text-center" style="font-size: 6rem;">
            <i class="fas fa-sync-alt" aria-hidden="true"> </i>
          </div>
        </b-col>
        <b-col cols="12" class="mb-2"
          ><div class="font-weight-bold tnt-gray-color text-center mb-3 h5">
            Anda diwajibkan untuk memperbarui Aplikasi Snapp
          </div></b-col
        >
        <b-col cols="12" class="mb-2">
          <button
            class="tnt-btn tnt-btn-prime btn-lg w-100 py-2"
            style="font-size: 1.3rem;"
            @click="submitUpdate()"
          >
            Perbarui Aplikasi
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<style lang="scss">
// Custom router view transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script>
// Import main layout components
import BaseHeader from "@/layouts/partials/Header";
import BaseSidebar from "@/layouts/partials/Sidebar";
// import BaseSideOverlay from '@/layouts/partials/SideOverlay'
// import BaseFooter from '@/layouts/partials/Footer'

export default {
  name: "BaseLayout",
  components: {
    BaseHeader,
    BaseSidebar
    // BaseSideOverlay,
    // BaseFooter
  },
  data: () => ({
    need_update: false
  }),
  props: {
    layoutClasses: Object
  },
  computed: {
    classContainer() {
      return {
        "sidebar-r":
          this.$store.state.layout.sidebar &&
          !this.$store.state.settings.sidebarLeft,
        "sidebar-mini":
          this.$store.state.layout.sidebar &&
          this.$store.state.settings.sidebarMini,
        "sidebar-o":
          this.$store.state.layout.sidebar &&
          this.$store.state.settings.sidebarVisibleDesktop,
        "sidebar-o-xs":
          this.$store.state.layout.sidebar &&
          this.$store.state.settings.sidebarVisibleMobile,
        "sidebar-dark":
          this.$store.state.layout.sidebar &&
          this.$store.state.settings.sidebarDark,
        "side-overlay-o":
          this.$store.state.layout.sideOverlay &&
          this.$store.state.settings.sideOverlayVisible,
        "side-overlay-hover":
          this.$store.state.layout.sideOverlay &&
          this.$store.state.settings.sideOverlayHoverable,
        "enable-page-overlay":
          this.$store.state.layout.sideOverlay &&
          this.$store.state.settings.pageOverlay,
        "page-header-fixed":
          this.$store.state.layout.header &&
          this.$store.state.settings.headerFixed,
        "page-header-dark":
          this.$store.state.layout.header &&
          this.$store.state.settings.headerDark,
        "main-content-boxed":
          this.$store.state.settings.mainContent === "boxed",
        "main-content-narrow":
          this.$store.state.settings.mainContent === "narrow",
        "rtl-support": this.$store.state.settings.rtlSupport,
        "side-trans-enabled": this.$store.state.settings.sideTransitions,
        "side-scroll": true,
        "darkmode-custom": this.$store.state.app.darkmodea
        // "need-update" : this.$store.state.app.needUpdate
      };
    }
  },
  created() {
    // Set default color theme
    this.$store.commit("setColorTheme", {
      theme: this.$store.getters.appColorTheme
    });
  },

  mounted() {
    // need update
    document.addEventListener("updateApps", this.getUpdate, { once: true });

    document.addEventListener("mouseup", () => {
      document.addEventListener("updateApps", this.getUpdate, { once: true });
    });
  },
  methods: {
    getUpdate(e) {
      if (e) {
        this.need_update = e.detail;
      }
    },
    submitUpdate() {
      this.$axios
        .post("/v2/user/other/update-app", {}, this.gl_req_header())
        .then(res => {
          if (res.status === 200) {
            if ("caches" in window) {
              console.log(caches);
              caches.keys().then(function(cacheNames) {
                cacheNames.forEach(function(cacheName) {
                  caches.delete(cacheName);
                });
              });
            }
            location.reload();
          }
        })
        .catch(e => {
          this.notifToast("danger", this.gl_catch_err(e));
        });
    }
  }
};
</script>
