import Vue from "vue";
import router from "./router";

import App from "./App.vue";
import store from "./store";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
Vue.use(VueViewer);

import pdf from 'vue-pdf';
Vue.component('pdf', pdf);

import vmodal from "vue-js-modal";
Vue.use(vmodal, { componentName: "cmodal" });

import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import BaseLayoutModifier from "@/components/BaseLayoutModifier";
import BaseBlock from "@/components/BaseBlock";
import BaseBackground from "@/components/BaseBackground";
import BasePageHeading from "@/components/BasePageHeading";
import BaseNavigation from "@/components/BaseNavigation";

import clickRipple from "@/directives/clickRipple";
import toggleClass from "@/directives/toggleClass";
import "./registerServiceWorker";
import "@/directives/filters";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import "./assets/scss/global-custom.scss";

import vueNumeralFilterInstaller from "vue-numeral-filter";
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import BackBtn from "@/views/mobile/components/BackBtn";
Vue.component(BackBtn.name, BackBtn);

import Breadcrumb from "@/views/mobile/components/Breadcrumb";
Vue.component(Breadcrumb.name, Breadcrumb);

import 'leaflet/dist/leaflet.css';

import md5 from "md5";  
import NProgress from 'nprogress'; // Opsional: untuk indikator loading
import 'nprogress/nprogress.css'; // Opsional: CSS untuk NProgress


// Axios
import axios from "axios";
// import axios from '@/utils/axios';

const axiosConfig = {
  // Don't forget change on route also!
  // baseURL: 'https://apitnt.stagetrv.xyz/',
  baseURL: process.env.VUE_APP_API_URL
};

 
 

Vue.prototype.$axios = axios.create(axiosConfig);

// Buat instance Axios 
Vue.prototype.$axios.interceptors.request.use((config) => {  
    // Start progress bar
  if (!config.url.includes("notification/sidebar")) {
    NProgress.start(); 
  } 
  return config;
});

// Add response interceptor
Vue.prototype.$axios.interceptors.response.use(
  (response) => { 
    NProgress.done(); // Stop progress bar
    return response;
  },
  (error) => {
    NProgress.done(); // Stop progress bar on error
    return Promise.reject(error);
  }
);  



// Register global components
Vue.component(BaseLayoutModifier.name, BaseLayoutModifier);
Vue.component(BaseBlock.name, BaseBlock);
Vue.component(BaseBackground.name, BaseBackground);
Vue.component(BasePageHeading.name, BasePageHeading);
Vue.component(BaseNavigation.name, BaseNavigation);

// Register global directives
Vue.directive("click-ripple", clickRipple);
Vue.directive("toggle-class", toggleClass);

// Disable tip shown in dev console when in development mode
Vue.config.productionTip = false;

Vue.mixin({
  data: function() {
    return {
      get currentStage() {
        //development
        //staging
        //production
        return process.env.VUE_APP_ENV;
      },
      // get currentVersion() {
      //   return process.env.VUE_APP_VERSION;
      // },
      get productionColor() {
        return "FAFAFA";
      },
      get developmentColor() {
        return "5DB025";
      },
      get stagingColor() {
        return "BBDEE9";
      },
      get userLoginxx() {
        return JSON.parse(localStorage.getItem("user-info"));
      }
    };
  },

  created() {
    if (this.currentStage === "development") {
      
      // console.log('development');
      // this.UnRegisterWorker();
      // this.setRegisterWorker(); 
      return;
    } else if (this.currentStage === "staging") {
      return;
      // console.log('staging');
      // this.UnRegisterWorker();
      // this.setRegisterWorker(); 
    } else {
      return;
      // console.log('production');
      // this.UnRegisterWorker();
      // this.setRegisterWorker(); 
    } 
  },

  mounted() {
    // if (this.currentStage === "development") {
    //   console.log('dev');
    //   this.UnRegisterWorker();
    //   this.setRegisterWorker(); 
    // } else if (this.currentStage === "staging") {
    //   console.log('stag');
    //   this.UnRegisterWorker();
    //   this.setRegisterWorker(); 
    // } else {
    //   console.log('pro');
    //   this.UnRegisterWorker();
    //   this.setRegisterWorker(); 
    // }
  },

  methods: {
    thousandSeparator: function (args) {
      
      let extractStr = args.toString();
      let digitCounts = extractStr.length;
      if (digitCounts === 3) {
        let parts = {};
        parts[1] = extractStr.substr(0, 1);
        parts[2] = extractStr.substr(1, 4);
        // return parts[1] + "." + parts[2];
        return extractStr;
      }

      if (digitCounts === 4) {
        let parts = {};
        parts[1] = extractStr.substr(0, 1);
        parts[2] = extractStr.substr(1, 4);
        return parts[1] + "." + parts[2];
      }
      if (digitCounts === 5) {
        let parts = {};
        parts[1] = extractStr.substr(0, 2);
        parts[2] = extractStr.substr(2, 5);
        return parts[1] + "." + parts[2];
      }
      if (digitCounts === 6) {
        let parts = {};
        parts[1] = extractStr.substr(0, 3);
        parts[2] = extractStr.substr(3, 6);
        return parts[1] + "." + parts[2];
      }
      if (digitCounts === 7) {
        let parts = {};
        parts[1] = extractStr.substr(0, 1);
        parts[2] = extractStr.substr(1, 3);
        parts[3] = extractStr.substr(4, 7);
        return parts[1] + "." + parts[2] + "." + parts[3];
      }
      if (digitCounts === 8) {
        let parts = {};
        parts[1] = extractStr.substr(0, 2);
        parts[2] = extractStr.substr(2, 3);
        parts[3] = extractStr.substr(5, 7);
        return parts[1] + "." + parts[2] + "." + parts[3];
      }
      if (digitCounts === 9) {
        let parts = {};
        parts[1] = extractStr.substr(0, 3);
        parts[2] = extractStr.substr(3, 3);
        parts[3] = extractStr.substr(6, 8);
        return parts[1] + "." + parts[2] + "." + parts[3];
      }
    },

    notifToast(variant, content) {
      // variant: success, danger
      this.$bvToast.toast(content, {
        title: variant == "success" ? "Sukses" : "Terjadi Kesalahan",
        variant: variant,
        solid: true
      });
    },

    getFilterDesc(tglawal, tglakhir, status_map, status, keyword) {
      const monthArr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des"
      ];
      let stringStatus = "";
      status_map.map(function (el) {
        if (el.value == status) {
          stringStatus = el.text;
        }
      });

      let filterDesc = "<i>Menampilkan data </i>";

      if (tglawal && tglakhir) {
        const tglawalParsed = new Date(Date.parse(tglawal));
        const tglAwalFormatted =
          tglawalParsed.getDate() +
          " " +
          monthArr[tglawalParsed.getMonth()] +
          " " +
          tglawalParsed.getFullYear();

        const tglAkhirParsed = new Date(Date.parse(tglakhir));
        const tglAkhirFormatted =
          tglAkhirParsed.getDate() +
          " " +
          monthArr[tglAkhirParsed.getMonth()] +
          " " +
          tglAkhirParsed.getFullYear();

        filterDesc +=
          "<i>tanggal </i>" +
          "<b>" +
          tglAwalFormatted +
          "</b>" +
          " <i>hingga</i> " +
          "<b>" +
          tglAkhirFormatted +
          "</b>";
      }

      if (stringStatus) {
        filterDesc += "<i>, status </i>" + "<b>" + stringStatus + "</b>";
      }

      if (keyword) {
        filterDesc += "<i>, pencarian<i> " + "<b>" + keyword + "</b>";
      }

      return filterDesc;
    },

    getFilterDesc2(tglawal, tglakhir, status_map, status, keyword) {
      const monthArr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des"
      ];
      let stringStatus = "Semua";
      
      status_map.map(function (el) {
        if (el.value == status) {
          stringStatus = el.text;
        }
      });

      let filterDesc = "<i>Menampilkan data </i>";

      if (tglawal && tglakhir) {
        const tglawalParsed = new Date(Date.parse(tglawal));
        const tglAwalFormatted =
          tglawalParsed.getDate() +
          " " +
          monthArr[tglawalParsed.getMonth()] +
          " " +
          tglawalParsed.getFullYear();

        const tglAkhirParsed = new Date(Date.parse(tglakhir));
        const tglAkhirFormatted =
          tglAkhirParsed.getDate() +
          " " +
          monthArr[tglAkhirParsed.getMonth()] +
          " " +
          tglAkhirParsed.getFullYear();

        filterDesc +=
          "<i>tanggal </i>" +
          "<b>" +
          tglAwalFormatted +
          "</b>" +
          " <i>hingga</i> " +
          "<b>" +
          tglAkhirFormatted +
          "</b>";
      }

      if (stringStatus) {
        filterDesc += "<i>, status </i>" + "<b>" + stringStatus + "</b>";
      }

      if (keyword) {
        filterDesc += "<i>, pencarian<i> " + "<b>" + keyword + "</b>";
      }

      return filterDesc;
    },

    getFilterEmp(tglawal, tglakhir, employee, company, divisi) {
      const monthArr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des"
      ];

      let stringStatus = "";
      if (!employee && !company && !divisi) {
        stringStatus = "Semua";
      }

      let filterDesc = "<i>Menampilkan data </i>";

      if (tglawal && tglakhir) {
        const tglawalParsed = new Date(Date.parse(tglawal));
        const tglAwalFormatted =
          tglawalParsed.getDate() +
          " " +
          monthArr[tglawalParsed.getMonth()] +
          " " +
          tglawalParsed.getFullYear();

        const tglAkhirParsed = new Date(Date.parse(tglakhir));
        const tglAkhirFormatted =
          tglAkhirParsed.getDate() +
          " " +
          monthArr[tglAkhirParsed.getMonth()] +
          " " +
          tglAkhirParsed.getFullYear();

        filterDesc +=
          "<i>tanggal </i>" +
          "<b>" +
          tglAwalFormatted +
          "</b>" +
          " <i>hingga</i> " +
          "<b>" +
          tglAkhirFormatted +
          "</b>";
      }

      if (stringStatus) {
        filterDesc += "<i>, status </i>" + "<b>" + stringStatus + "</b>";
      }

      // if (keyword) {
      //   filterDesc += "<i>, pencarian<i> " + "<b>" + keyword + "</b>";
      // }

      return filterDesc;
    },

    getFormatdate(tgl) {
      const monthArr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des"
      ];
       
      let tglFormatted = "";
      if (tgl) {
        const tglParsed = new Date(Date.parse(tgl));
        tglFormatted =
          tglParsed.getDate() +
          " " +
          monthArr[tglParsed.getMonth()] +
          " " +
          tglParsed.getFullYear();
      } 
      return tglFormatted;
    },

    getDefaultDate(minus = 0) {
      //kalo mau tgl kedepan, pake minus
      let date = new Date();
      date.setDate(date.getDate() - minus);
      date = date.toISOString().split("T")[0];

      return date;
    },

    getDefaultTime() {
      let date = new Date();
      return date.getHours() + ":" + date.getMinutes()
    },

    setDefaultDate(month = 1, year = 2024) {
      let date = new Date();
      date.setFullYear(year, month - 1, 1);
      date = date.toISOString().split("T")[0];

      return date;
    },

    difday(d1, d2) {
      if (d1 && d2) {
        let t1 = new Date(d1);
        let t2 = new Date(d2);

        t1 = t1.getTime();
        t2 = t2.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000) + 1);
      } else {
        return 0;
      }
    },

    isLeapYear(year) {
      return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
    },

    getDaysInMonth(year, month) {
       return [31, (this.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    },

    addMonth(month = 1, oldDate, effDate) {     
      if (oldDate) {        
        let d = new Date(oldDate)
        let e = new Date(effDate);

        let y = d.getFullYear();
        let m = d.getMonth();
        let h = e.getDate();   
        /* jika di bulan desember / bln 11 */
        if (m === 11) {
          m = 0; 
          y = y + 1;
          let a = this.getDaysInMonth(y, m); 
           
          if (a < h) {
            d = new Date(y, m, a );
          }
          else
          {
            d = new Date(y, m, h);
          } 
        }
        else {
          m = m + month;
          let a = this.getDaysInMonth(y, m);
         
          if (a < h) { 
            d = new Date(y, m, a);
          }
          else
          {
            d = new Date(y, m, h);
          } 
        }        
        return this.formatDateToYYYYMMDD(d); 
      } 
      return this.getDefaultDate();
    },

    formatDateToYYYYMMDD(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      let day = String(date.getDate()).padStart(2, '0');
      return year + '-' + month + '-' + day;//  ${year}-${month}-${day};
    },

    getDateYYYYMMDD() {
      let d = new Date();
      let year = d.getFullYear();
      let month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      let day = String(d.getDate()).padStart(2, '0');
      return year + '-' + month + '-' + day; 
    },

    getTimeNow() {
      const today = new Date();
      let minut = today.getMinutes();
      if (minut < 10) {
        minut = "0" + today.getMinutes();
      }

      let second = today.getSeconds();
      if (second < 10) {
        second = "0" + today.getSeconds();
      }  
      return today.getHours() + ":" + minut + ":" + second;
    },


    viewFile(data) {
      if (data.file_type !== null) {
        let urls = [];
        let file_url = "";
        if (data.file_path) {
          file_url = data.file_path;
        } else if (data.url) {
          file_url = data.url;
        }
        if (file_url == "") {
          file_url = data.file_url;
        }
        switch (data.file_type) {
          case "JPG":
          case "JPEG":
          case "PNG":
            urls.push(file_url);
            this.$viewerApi({ images: urls });
            break;

          // case "PDF":
          //   this.pdfName = data.file_name;
          //   this.pdfUrl = data.url;
          //   this.pdfShown = true;
          //   // console.log(data);
          //   break;

          default:
            window.open(file_url, "_blank");
            break;

          // case "PDF":
          //   window.open(file_url, "_blank");
          //   urls.push(file_url);
          //   break;
        }
      }
      // else {
      // let urls = [];
      // urls.push(file_url);
      // this.$viewerApi({ images: urls });
      // window.open(file_url, "_blank");
      //   urls.push(file_url);
      //   break;
      // window.open(file_url, "_blank");
      // }
    },

    getMonthYear() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      const now = new Date();
      const now_month = now.getMonth();
      const now_year = now.getFullYear();
      let tmp_month = now_month;
      let tmp_year = now_year;

      //set bulan dropdown
      let monthList = [];
      for (let index = 1; index <= 13; index++) {
        monthList.push({
          value: tmp_year + "-" + ("0" + (tmp_month + 1)).slice(-2),
          text: monthNames[tmp_month] + " " + tmp_year
        });

        tmp_month = tmp_month - 1;
        if (tmp_month == -1) {
          tmp_month = 11;
          tmp_year = tmp_year - 1;
        }
      }

      let monthCurrent = now_year + "-" + ("0" + (now_month + 1)).slice(-2);

      let arr = [];
      arr["month_current"] = monthCurrent;
      arr["month_list"] = monthList;

      return arr;
    },

    gl_time_format(time, type = 1) {
      if (type == 1) {
        // console.log(time.substring(0, 5));
        return time.substring(0, 5);
      } else {
        return time;
      }
    },

    gl_req_header(type = "general", additional = []) {
      //how to use, when call kek gini ya
      //this.gl_req_header()
      //this.gl_req_header("media")
      //this.gl_req_header("general", [{aa: 'ok'},{ bb: 'ok'}])

      //default headers
      let config = {
        headers: {
          "x-api-key": localStorage.getItem("user-token")
          // "x-api-key": this.userLoginxx.em_login_token
        }
      };

      //if media
      if (type == "media") {
        let multipartHeader = { "Content-Type": "multipart/form-data" };
        config.headers = {
          ...config.headers,
          ...multipartHeader
        };
      }

      //push additional
      if (additional.length > 0) {
        additional.forEach(value => {
          config.headers = {
            ...config.headers,
            ...value
          };
        });
      }

      return config;
    },

    gl_catch_err(e) {
      const err = e.response.data.error;
      if (typeof err === "string" || err instanceof String) {
        return err;
      } else if (typeof err === "object" && err !== null) {
        let msg = "";
        Object.values(err).map((aa) => {

          msg = aa[0];

        });
        return msg;
      }

      return "Terjadi Kesalahan #NF";
    },

    gl_check_permission(key) {

      const userPermissions = JSON.parse(
        localStorage.getItem("user-info")
      );

      if (userPermissions.menu_permission.includes(key)) return true;

      return false;
    },

    gl_switchDarkMode() {
      this.$store.state.app.darkmodea = !this.$store.state.app.darkmodea

      if (localStorage.getItem("darkmode-custom") == null) {
        localStorage.setItem("darkmode-custom", 'on');
        return;
      }
      
      if (localStorage.getItem("darkmode-custom") === 'on') {
        localStorage.setItem("darkmode-custom", 'off');
        return;
      }
      
      if (localStorage.getItem("darkmode-custom") === 'off') {
        localStorage.setItem("darkmode-custom", 'on');
        return;
      }

    },

    groupBy(data, property) {
      return data.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },

    getYearMonth() {
      let arr = [];
      const d = new Date();
      const y = d.getFullYear();
      const n = y + 1;
      const m = d.getMonth();
      const s = 1950;  //y - 50;
      const b = [
        { id: 1, val: "01", text: "January" },
        { id: 2, val: "02", text: "February" },
        { id: 3, val: "03", text: "March" },
        { id: 4, val: "04", text: "April" },
        { id: 5, val: "05", text: "May" },
        { id: 6, val: "06", text: "June" },
        { id: 7, val: "07", text: "July" },
        { id: 8, val: "08", text: "August" },
        { id: 9, val: "09", text: "September" },
        { id: 10, val: "10", text: "October" },
        { id: 11, val: "11", text: "November" },
        { id: 12, val: "12", text: "December" }
      ];
      
      for (let i = s; i < n; i++) {
        if (i != y) {
          b.map(v => {
            const ym = i.toString() + "-" + v.val;
            const tm = v.text + " " + i.toString();
            arr.push({ value: ym, text: tm });
          });
        } else {
          b.map(v => {
            if (v.id <= m) {
              const ym = i.toString() + "-" + v.val;
              const tm = v.text + " " + i.toString();
              arr.push({ value: ym, text: tm });
            }
          });
        }
      }
      return arr.reverse()
    },

    formatMoney(amount = 0, decimalCount = 0, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign +
          (j ? i.substr(0, j) + thousands : '') +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    },

    formatFloat(amount) {
      if (amount === null) {
        return parseFloat(0)
      }
      // let temp = amount.toString().replace(/[^0-9.-]+/g, ""); 
      let temp = amount.toString().replace(/[^\d-]/g, "");
      return parseFloat(temp);
      // return temp
    },

    generateString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = ' ';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    generateHash(v) {
      if (v === null || v === "") return ""
      return md5(v);
    },

    hasNumberStringAndSymbol(str) {
      const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).+$/;
      return regex.test(str);
    },

    filterByValue(array, string) {
      let filtered = Array();

      for (let i = 0; i < array.length; i++) {
        const keys = Object.keys(array[i]);

        for (let j = 0; j < keys.length; j++) {
          const value = array[i][keys[j]];

          if (
            typeof value === "string" &&
            value.toLowerCase().includes(string.toLowerCase())
          ) {
            filtered.push(array[i]);
            break;
          } else {
            continue;
          }
        }
      }
      return filtered;
    },

    GenerateYear() {
      let year = [];
      for (let i = 2024; i < 2051; i++) {
        year.push({ label: i, value: i });
      }
      return year;
    },

    setRegisterWorker() {
      if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) => {
              console.log('Service Worker registered with scope:', registration.scope);

              // When a new Service Worker is installed, listen for the `controllerchange` event
              navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (registration.waiting) {
                  console.log('New Service Worker controlling the page. Reloading...');
                  // window.location.reload(); 
                }
              });

              registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                  if (installingWorker.state === 'installed') {
                    // If there's a new Service Worker available, notify the user  
                   
                    if (navigator.serviceWorker.controller) {
                      console.log('New content available; please refresh.');
                      setTimeout(() => {
                        window.location.reload();
                      }, 5000);
                      window.location.reload();
                     
                    } else {
                      console.log('Content cached for offline use.');
                    }
                  }
                };
              };
            })
            .catch((error) => {
              console.error('Service Worker registration failed:', error);
            });
        });
      }
    },

    UnRegisterWorker() {
      this.setClearCache();
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          registrations.forEach((registration) => {
            registration.unregister().then((successful) => {
              if (successful) {
                console.log('Service worker unregistered');
              }
            });
          });
        });
      }
    },

    setClearCache() {
      if ("caches" in window) {
        caches.keys().then(function (cacheNames) {
          cacheNames.forEach(function (cacheName) {
            caches.delete(cacheName);
          });
        });
        location.reload();
      }
    },

    sendLog(a, b, c) {
      if (!a || !b || !c) return;
      const requestBody = {
        action: a,
        tag: b,
        desc: c,
      };

      this.$axios
        .post("/v2/admin/setting/action-log/create", requestBody, this.gl_req_header())
        .then(res => {
          if (res.status === 200) {
            // console.log(res.status)
          }
        })
        .catch(e => {
          console.log(e)
          // this.notifToast("danger", this.gl_catch_err(e)); 
        });
    },

    getStatus(stt) {         
      let R = 'A';
      switch (stt) {
        case 'WAITING_APPROVAL':
          R = 'B';
          break; 
        case 'ACCEPT':
          R = 'C';
          break; 
        case 'CANCELLED':
          R = 'F';
          break;
        case 'REJECT':
          R = 'D';
          break;
        case 'DELETED':
          R = 'E';
          break;
        default: 
          R = 'A';
          break;
      }
      return R 
    },  

     
  }
});

// Craft new application
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
