<template>
  <button
    id="btn-refresh"
    class="tnt-btn tnt-btn-prime"
    @click="refresh($event)"
    v-bind:style="styleObject"
  >
    {{ btnText }}
  </button>
</template>

<script>
export default {
  name: "UpdateAppBtn",
  data: () => ({
    styleObject: {}
  }),
  props: ["alwaysShow", "btnText"],
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.alwaysShow == "true") {
        this.styleObject = {
          display: "inline-block"
        };
      }
    },

    refresh(e) {
      e.disabled = true;
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
      // if ("caches" in window) {
      //   console.log(caches);
      //   caches.keys().then(function(cacheNames) {
      //     cacheNames.forEach(function(cacheName) {
      //       caches.delete(cacheName);
      //     });
      //   });
      // }
      // localStorage.setItem("app-version", process.env.VUE_APP_VERSION);
      location.reload();
    }
  }
};
</script>
<style scoped>
#btn-refresh {
  display: none;
}
</style>
