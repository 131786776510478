<template>
  <!-- Header -->
  <header id="page-header" style="background-color: #fafafa; box-shadow: none">
    <slot>
      <!-- Header Content -->
      <div class="content-header" style="margin-left: 15px; padding-left: 0px">
        <!-- Left Section -->
        <div class="d-flex align-items-center">
          <!-- Toggle Sidebar -->
          <base-layout-modifier
            v-if="!clean_dashboard"
            action="sidebarToggle"
            size="sm"
            variant="dual"
            class="mr-2 d-lg-none"
          >
            <i class="fa fa-fw fa-bars"></i>
          </base-layout-modifier>

          <span class="mr-2 d-lg-none d-flex">
            <img
              src="@/assets/icons/logo_color.png"
              @click="$router.push({ name: 'Dashboard' })"
              class="cursor-pointer"
              style="height: 30px"
            />

            <small class="ml-2 tnt-gray-color" style="line-height: 30px;"
              >v{{ ver }}</small
            >

            <AddToHomeScreen class="ml-2" />
          </span>

          <!-- END Toggle Sidebar -->

          <!-- Toggle Mini Sidebar -->
          <base-layout-modifier
            action="sidebarMiniToggle"
            size="sm"
            variant="dual"
            class="mr-2 d-none d-lg-inline-block"
          >
            <i class="fa fa-fw fa-ellipsis-v" v-if="!clean_dashboard"></i>
          </base-layout-modifier>
          <!-- END Toggle Mini Sidebar -->
        </div>
        <!-- END Left Section -->

        <!-- Right Section -->
        <div class="d-flex align-items-center">
          <div class="mr-3 cursor-pointer" title="Check For Updates">
            <i
              v-if="!clean_dashboard"
              class="fas fa-redo"
              @click="
                setClearCache();
                sendLog(
                  'CLICK',
                  'CLEAR_CACHE_FROM_HEADER',
                  'Click, current v' + ver
                );
              "
            ></i>
          </div>

          <div class="mr-2 cursor-pointer">
            <i
              v-if="!clean_dashboard"
              :class="[
                'fas',
                $store.state.app.darkmodea ? 'fa-sun' : 'fa-moon'
              ]"
              @click="gl_switchDarkMode()"
            ></i>
          </div>

          <!-- Notifications Dropdown -->
          <!-- <b-dropdown
            size="sm"
            class="d-inline-block ml-2"
            toggle-class="text-decoration-none icon-transparent"
            right
            no-caret
          >
            <template #button-content>
              <i class="fa fa-fw fa-bell"></i>
              <span v-if="notifications.length" class="text-primary">•</span>
            </template>
            <li>
              <div class="p-2">
                <span
                  class="font-common"
                  style="font-weight: bold; font-size: 12px"
                >
                  Notifikasi
                </span>
              </div>
              <ul class="nav-items mb-0" style="max-width: 100vw">
                <li
                  v-for="(notification, index) in notifications"
                  :key="`notification-${index}`"
                  class="border-bottom"
                >
                  <div
                    class="d-flex px-2 py-1"
                    style="justify-content: space-between; cursor: pointer"
                    :style="getColor(notification.n_read)"
                    @click="setRead(notification)"
                  >
                    <div class="">
                      <div
                        class="font-common"
                        style="font-weight: bold; font-size: 12px"
                      >
                        {{ notification.n_title }}
                        <span
                          class="font-common ml-2"
                          style="font-size: 10px; color: #cccccc"
                        >
                          {{ notification.created_at }}
                        </span>
                      </div>
                      <div
                        class="font-common"
                        style="font-size: 12px"
                        v-html="notification.n_content"
                      ></div>
                    </div>
                  </div>
                </li>
                <li v-if="!notifications.length" class="p-2"> 
                  <div class="text-center">
                    <p class="mb-0 font-common" style="font-size: 12px">
                      Tidak Ada Notifikasi
                    </p>
                  </div> 
                </li>
              </ul>
              <div v-if="notifications.length" class="p-2 border-top">
                <div class="text-center py-1">
                  <span
                    class="font-common"
                    style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #008eb7;
                      cursor: pointer;
                    "
                    @click="toNotifikasi"
                  >
                    Lihat Selengkapnya
                  </span>
                </div>
              </div>
            </li>
          </b-dropdown> -->
          <!-- END Notifications Dropdown -->

          <b-dropdown
            ref="dropdown"
            size="sm"
            variant="dual"
            class="d-inline-block"
            menu-class="p-0 font-size-sm"
            right
            no-caret
          >
            <template #button-content>
              <span>
                {{ name }}
              </span>
            </template>

            <b-dropdown-text
              v-if="!clean_dashboard"
              @click="pengaturan"
              class="cursor-pointer py-1 font-common dd-drop"
            >
              Akun
            </b-dropdown-text>
            <b-dropdown-text
              @click="logout"
              class="cursor-pointer py-1 font-common dd-drop"
            >
              Logout
            </b-dropdown-text>
          </b-dropdown>
        </div>
        <!-- END Right Section -->
      </div>
      <!-- END Header Content -->
    </slot>
  </header>
  <!-- END Header -->
</template>

<script>
import AddToHomeScreen from "@/components/AddToHomeBtn";

export default {
  name: "BaseHeader",
  props: {
    classes: String
  },
  components: { AddToHomeScreen },
  data: () => ({
    name: JSON.parse(localStorage.getItem("user-info")).fname,

    baseSearchTerm: "",
    notifications: [],

    ver: process.env.VUE_APP_VERSION,

    usrPhoto: null,
    clean_dashboard: false
  }),

  created() {
    // console.log(JSON.parse(localStorage.getItem("user-info")).menu_permission);
    // this.initialize();
    this.getNotifikasi();
  },

  methods: {
    initialize() {
      this.$axios
        .post("/v2/user/init", {}, this.gl_req_header())
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem("user-info", JSON.stringify(res.data.data));
            // console.log(localStorage.getItem("user-info").menu_permission);
            if (res.data.data.photo_format !== null) {
              this.usrPhoto = res.data.data.photo_format.url_thumb;
            }

            this.clean_dashboard = res.data.data.clean_dashboard;

            if (this.clean_dashboard) {
              this.$store.commit("sidebar", { mode: "close" });
            }
          }
        })
        .catch(e => {
          this.notifToast("danger", e);
        });
    },

    getNotifikasi() {
      this.$axios
        .post(
          "/v2/general/notification",
          {
            page: 1
          },
          this.gl_req_header()
        )
        .then(res => {
          if (res.status === 200) {
            // console.log(res.data.data)
            this.notifications = res.data.data.notification.list;
          }
        })
        .catch(e => {
          this.notifToast("danger", this.gl_catch_err(e));
        });
    },

    setRead(v) {
      this.$axios
        .post(
          "/v2/general/notification/update-notif",
          {
            n_hash: v.n_hash
          },
          this.gl_req_header()
        )
        .then(res => {
          if (res.status === 200) {
            this.notifToast("success", res.data.message);
            this.getNotifikasi();
          }
        })
        .catch(e => {
          this.notifToast("danger", this.gl_catch_err(e));
        });
    },

    getColor(read) {
      let clr = "background: rgba(0, 142, 183, 0.1)";
      if (read) clr = "background: #FFFFFF";

      return clr;
    },

    onSubmit() {
      this.$router.push("/backend/pages/generic/search?" + this.baseSearchTerm);
    },

    eventHeaderSearch(event) {
      // When ESCAPE key is hit close the header search section
      if (event.which === 27) {
        event.preventDefault();
        this.$store.commit("headerSearch", { mode: "off" });
      }
    },

    pengaturan() {
      this.$refs.dropdown.hide(true);
      if (this.$route.name != "Settings") {
        this.$router.push({ name: "Settings" });
      }
    },

    toNotifikasi() {
      this.$router.push({ name: "Notifikasi" });
    },

    async logout() {
      await this.$axios
        .post("/v2/logout", {}, this.gl_req_header())
        .then(res => {
          if (res.status === 200) {
            // console.log(res)
            localStorage.clear();
            this.$router.push({ name: "Login" });
          }
        })
        .catch(e => {
          if (typeof e.response.data.error === "object") {
            this.notifToast("danger", Object.values(e.response.data.error)[0]);
          } else {
            this.notifToast("danger", this.gl_catch_err(e));
          }
        });
    }
  },

  mounted() {
    document.addEventListener("keydown", this.eventHeaderSearch);
    this.initialize();
  },

  destroyed() {
    document.removeEventListener("keydown", this.eventHeaderSearch);
  }
};
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #808080;
}
</style>
