var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-inline-block" }, [
    _vm.deferredPrompt
      ? _c(
          "button",
          {
            ref: "addBtn",
            staticClass: "tnt-btn tnt-btn-prime rounded px-3 py-1",
            on: { click: _vm.clickCallback }
          },
          [_vm._v("\n    Install\n  ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }