var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "block",
      class: _vm.classContainer,
      attrs: { href: _vm.tag === "a" ? "#" : false }
    },
    [
      _vm.$slots.header || _vm.$slots.title || _vm.title
        ? _c(
            "div",
            { staticClass: "block-header", class: _vm.classContainerHeader },
            [
              _vm._t("header", [
                _c(
                  "h3",
                  { staticClass: "block-title" },
                  [
                    _vm._t("title"),
                    _vm._v("\n        " + _vm._s(_vm.title) + " "),
                    _vm.subtitle
                      ? _c("small", [_vm._v(_vm._s(_vm.subtitle))])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("subtitle")
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.$slots.options ||
                _vm.btnOptionFullscreen ||
                _vm.btnOptionPinned ||
                _vm.btnOptionContent ||
                _vm.btnOptionClose
                  ? _c(
                      "div",
                      {
                        staticClass: "block-options",
                        class: _vm.classContainerOptions
                      },
                      [
                        _vm._t("options"),
                        _vm._v(" "),
                        _vm.btnOptionFullscreen
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-block-option",
                                attrs: { type: "button" },
                                on: { click: _vm.fullscreenToggle }
                              },
                              [
                                _c("i", {
                                  class: {
                                    "si si-size-fullscreen": !_vm.optionFullscreen,
                                    "si si-size-actual": _vm.optionFullscreen
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.btnOptionPinned
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-block-option",
                                attrs: { type: "button" },
                                on: { click: _vm.pinnedToggle }
                              },
                              [_c("i", { staticClass: "si si-pin" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.btnOptionContent
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-block-option",
                                attrs: { type: "button" },
                                on: { click: _vm.contentToggle }
                              },
                              [
                                _c("i", {
                                  class: {
                                    "si si-arrow-up": !_vm.optionContentHide,
                                    "si si-arrow-down": _vm.optionContentHide
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.btnOptionClose
                          ? _c(
                              "button",
                              {
                                staticClass: "btn-block-option",
                                attrs: { type: "button" },
                                on: { click: _vm.close }
                              },
                              [_c("i", { staticClass: "si si-close" })]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.$slots.content
        ? _c(
            "div",
            { staticClass: "block-content", class: _vm.classContainerContent },
            [
              this.ribbon
                ? _c(
                    "div",
                    { staticClass: "ribbon-box" },
                    [
                      _vm._t("ribbon", [
                        _vm._v("\n        " + _vm._s(_vm.ribbon) + "\n      ")
                      ])
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("content")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }